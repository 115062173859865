import React, {useEffect, useRef} from "react";
import {headlineAnimation, terminAnimation} from "../Animation/animation";


const Termin = ({date, time, location, title, subtitle, link, info}) => {

    const terminRef = useRef();

    useEffect(() => {
        //terminAnimation(terminRef.current);
    })

    return (
        <div className={"termin__outer"}>
            <a className="termin__inner hover-moveup" target={"_blank"} href={link}>
                <div className="top">
                    <div className="left">
                        <p className="museo">{date}, {time}</p>
                    </div>
                    <div className="right">
                        <p className="museo">{location}</p>
                    </div>
                </div>
                <div className="content">
                    <div className="left">
                        <h2 className={"minion strong"}>{title}</h2>
                        <p className={"minion large"}>{subtitle}</p>
                        <p className={"minion"}>{info}</p>
                    </div>
                    <div className="right">
                        <div className="arrow-icon"></div>
                    </div>
                </div>
            </a>
            <div className="separator full" ref={terminRef}></div>
        </div>
    )
}


export default Termin
