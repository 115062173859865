import React from "react";
import Marquee from "react-fast-marquee";
import {GatsbyImage} from "gatsby-plugin-image";



const BookImages = ({images, title}) => {


    return (
        <div className={"bookimages__outer container"}>
            <div className="bookimages__inner">
                <Marquee gradient={false} speed={20}>
                    {images.map(el => {
                        return <div className="bookimages__wrapper">
                            <GatsbyImage image={el.image.asset.gatsbyImageData} alt={title}></GatsbyImage>
                            <div className="tooltip">{el.credits}</div>
                        </div>
                    })}
                    {images.map(el => {
                        return <div className="bookimages__wrapper">
                            <GatsbyImage image={el.image.asset.gatsbyImageData} alt={title}></GatsbyImage>
                            <div className="tooltip">{el.credits}</div>
                        </div>
                    })}
                </Marquee>
            </div>
        </div>
    )
}


export default BookImages
