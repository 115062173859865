import React from "react";
import Headline from "./Headline";
import Termin from "./Termin";


const Downloads = ({downloads}) => {

    return (
        <div className={"downloads__outer container"}>
            <div className="downloads__inner">
                <Headline>Downloads</Headline>
                <div className="downloads__list">
                    {downloads.map(el => {
                        return <a href={el.file.asset.url} target={"_blank"} download className="btn fit">{el.title}</a>
                    })}
                </div>
            </div>
        </div>
    )
}


export default Downloads
