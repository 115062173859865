import React from "react";
import Headline from "./Headline";
import Termin from "./Termin";


const Termine = ({termine}) => {

    return (
        <div className={"termine__outer container"}>
            <div className="termine__inner">
                <Headline>Termine</Headline>
                <div className="termine__list">
                    {termine.map(el => {
                        return <Termin date={el.datum} time={el.uhrzeit} location={el.location} title={el.title} subtitle={el.subtitle} link={el.link} info={el.info}></Termin>
                    })}
                </div>
            </div>
        </div>
    )
}


export default Termine
