import React, {useEffect, useRef} from "react";
import MyPortableText from "./MyPortableText";
import Barcode from 'react-barcode';
import {headlineAnimation} from "../Animation/animation";


const Ausgabe = ({isbn, text, kauflink}) => {

    const headlineRef = useRef();

    useEffect(() => {
        headlineAnimation(headlineRef.current);
    })

    return (
        <div className={"ausgabe__outer container"}>
            <div className="ausgabe__inner">
                <Barcode value={isbn} width={"100%"} margin={0} background={"transparent"} displayValue={false}/>
                <h1 className="minion">ISBN {isbn}</h1>
                <div className="separator" ref={headlineRef}></div>
                <div className="text center">
                    <MyPortableText value={text}></MyPortableText>
                </div>
                <a href={kauflink} className="btn outline full">Kaufen</a>
            </div>
        </div>
    )
}


export default Ausgabe
