import React from "react";
import { BsPlay, BsInstagram, BsFacebook, BsCamera } from 'react-icons/bs'
import {
    AiOutlineInstagram,
    AiOutlineCamera,
    AiOutlineYoutube,
    AiFillFacebook,
    AiFillInstagram,
    AiFillYoutube, AiOutlineTwitter
} from 'react-icons/ai'
import { ImFilm } from 'react-icons/im'
import {FiLinkedin} from 'react-icons/fi'
import {
    FaGlobeEurope,
    FaGoodreads,
    FaGoodreadsG,
    FaLinkedinIn,
    FaPinterest,
    FaTiktok,
    IoLogoTiktok
} from "react-icons/all";

const Icon = ({icon, color}) => {

    let iconColor = "svg-fill-dark";
    switch(color){
        case "light": iconColor = 'svg-fill-light';break;
        case "dark": iconColor = 'svg-fill-dark';break;
    }
    switch(icon){
        case 'facebook':
            return <AiFillFacebook className={iconColor}/>;
        case 'instagram':
            return <AiFillInstagram className={iconColor}/>;
        case 'youtube':
            return <AiFillYoutube className={iconColor}/>;
        case 'linkedin':
            return <FaLinkedinIn className={iconColor}/>;
        case 'goodreads':
            return <FaGoodreads className={iconColor}/>;
        case 'pinterest':
            return <FaPinterest className={iconColor}/>;
        case 'tiktok':
            return <IoLogoTiktok className={iconColor}/>;
        case 'website':
            return <FaGlobeEurope className={iconColor}/>;
        case 'twitter':
            return <AiOutlineTwitter className={iconColor}/>;
    }
    return (
        <></>
    )
}

export default Icon;
