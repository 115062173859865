import React, {useEffect, useRef} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {headlineAnimation} from "../Animation/animation";
import LandingBookContainer from "./LandingBookContainer";
import parseMarkdown from "../utils/parseMarkdown";


const BookLanding = ({title, subtitle, authors, cover, images}) => {

    return (
        <div className={"booklanding__outer container"}>
            <div className="booklanding__inner">
                <div className="title">
                    <p className="minion authors large center">
                        {authors.map((el, key) => {
                            return <span>{el.name}{key === authors.length - 1 ? '' : ', '}</span>
                        })}
                    </p>
                    <div className="separator"></div>
                    <h1 className="museo center" dangerouslySetInnerHTML={{ __html: parseMarkdown(title) }}></h1>
                    <h2 className="museo center">{subtitle}</h2>
                </div>
                {cover !== null ?
                <div className={images.length > 0 ? "images multipleimages" : "images"}>
                    <LandingBookContainer cover={cover} images={images} title={title}></LandingBookContainer>
                </div>
                    :
                    <></>
                }
            </div>
        </div>
    )
}


export default BookLanding

/*
var scrollContainer = document.querySelector('#product-img-container');
        scrollContainer.onscroll = function() {
            updateProgressBar()
        };

        function updateProgressBar() {
            console.log('update scroll');
            var winScroll = scrollContainer.scrollTop || scrollContainer.scrollTop;
            var height = scrollContainer.scrollHeight - scrollContainer.clientHeight;
            var scrolled = (winScroll / height) * 100;
            document.getElementById("productBar").style.height = scrolled + "%";
        }

<div className="images">

                </div>
        <div className="image__wrapper">
                        <GatsbyImage image={cover} alt={title}></GatsbyImage>
                    </div>
                    {images.map(el => {
                        return <div className="image__wrapper">
                            <GatsbyImage image={el.asset.gatsbyImageData} alt={title}></GatsbyImage></div>
                    })}
                    <div className="images__progressbar__container">
                        <div className="images__progressbar">

                        </div>
                    </div>
 */
