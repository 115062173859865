import React from "react";
import Headline from "./Headline";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import MyPortableText from "./MyPortableText";

const PresseStimmen = ({presse}) => {

    return (
        <div className={"presse__outer container"}>
            <div className="presse__inner">
               <Headline>Pressestimmen</Headline>
                <Swiper
                    className="mySwiper"
                    pagination={{
                        clickable: true,
                    }}
                    loop={true}
                    autoplay={{
                        delay: 30000,
                        disableOnInteraction: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}>
                    {presse.map((el) => {
                        return (
                            <SwiperSlide className={"presse__slide center"}>
                                <div className={"large minion"}>
                                    <MyPortableText value={el._rawText}></MyPortableText>
                                </div>
                                <p className={"museo name"}>{el.name}</p>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
        </div>
    )
}


export default PresseStimmen
