import React, {useRef} from "react";
import MyPortableText from "./MyPortableText";


const BookTexte = ({longtext, shorttext, title, link}) => {

    const copyLinkRef = useRef();

    function changeText(){
        copyLinkRef.current.innerText = "Kopiert!";
    }

    return (
        <div className={"booktexte__outer container"}>
            <div className="booktexte__inner">
                <div className="minion large short">
                    <MyPortableText value={shorttext}></MyPortableText>
                </div>
                <div className="minion long">
                    <MyPortableText value={longtext}></MyPortableText>
                </div>
                {longtext ?
                    <div className="sharebtn__container">
                        <p className="museo">Teilen:</p>
                        <div className={"sharebtn__inner__container"}>
                            <div>
                                <a href={"mailto:?subject=Schau%20dir%20mal%20dieses%20Buch%20an!&body=" + link} className="btn sharebtn">E-Mail</a>
                                <a href={"https://www.facebook.com/sharer/sharer.php?u=" + link} target={"_blank"} className="btn sharebtn">Facebook</a>
                            </div>
                            <div>
                                <a href={"https://twitter.com/intent/tweet?url=" + link + "&text="} className="btn sharebtn">Twitter</a>
                                <div onClick={() => {navigator.clipboard.writeText(link);changeText();}} className="btn sharebtn" ref={copyLinkRef}>Link Kopieren</div>
                            </div>
                        </div>
                    </div>
                :
                <></>}
            </div>
        </div>
    )
}


export default BookTexte
