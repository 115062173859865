import React, {useEffect, useRef} from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {headlineAnimation} from "../Animation/animation";


const LandingBookContainer = ({title, cover, images}) => {

    const imageRef = useRef();
    const scrollbarRef = useRef();

    const scrollThumb = () => {
        let winScroll = imageRef.current.scrollLeft;
        let width = imageRef.current.scrollWidth - imageRef.current.clientWidth;
        let scrolled = (winScroll / width) * 100;
        scrollbarRef.current.style.width = scrolled + "%";
    };

    useEffect(() => {
        imageRef.current.addEventListener('scroll', e => scrollThumb())
    })

    return (
        <>
            <div className={images.length > 0 ? "image__wrapper multiple" : "image__wrapper"} ref={imageRef}>
                <GatsbyImage image={cover} alt={title}></GatsbyImage>
                {images.length > 0 ? images.map(el => {
                    return <GatsbyImage image={el.asset.gatsbyImageData} alt={title}></GatsbyImage>
                }) : <></>}
            </div>
            <div className="images__progressbar__container">
                <div className="images__progressbar" ref={scrollbarRef}></div>
            </div>
        </>
    )
}


export default LandingBookContainer
