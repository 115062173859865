import * as React from "react"
import Layout from "../components/Layout";
import {graphql} from "gatsby";
import BookLanding from "../components/BookLanding";
import Downloads from "../components/Downloads";
import Termine from "../components/Termine";
import PresseStimmen from "../components/PresseStimmen";
import BookAuthor from "../components/BookAuthor";
import Ausgabe from "../components/Ausgabe";
import BookTexte from "../components/BookTexte";
import SEO from "../components/SEO";
import BookImages from "../components/BookImages";

export const query = graphql`
query($slug: String){
sanityBuch(slug: {current: {eq: $slug}}) {
    Authors {
            name
            _rawText
            facebook
            instagram
            website
            twitter
            youtube
            tiktok
            pinterest
           linkedin
           goodreads
          }
          ausgaben {
            kauflink
            isbn
            _rawInfo
          }
          cover {
            asset {
              gatsbyImageData
            }
          }
          downloads {
            title
            file {
              asset {
                url
              }
            }
          }
          
          pressestimmen {
            name
            _rawText
          }
          seitenbilder {
            asset {
              gatsbyImageData
            }
          }
          images {
          image{
            asset {
              gatsbyImageData
            }
            }
            credits
          }
          subtitle
          title
          _rawLangtext
          _rawKurztext
          events {
          datum
          link
          location
          subtitle
          info
          title
          uhrzeit
        }
}
allSanityAgbs {
  edges{
    node{
        agbfile {
          asset {
            url
          }
        }
    }
  }
  }
}
    `;

const BookPage = ({data, location}) => {

    const page = data.sanityBuch;
    console.log(page);
    return (
    <Layout agbs={data.allSanityAgbs.edges[0].node.agbfile.asset.url}>
        <SEO title={page.title + " | Matthey & Melchior"} description={page._rawKurztext ? page._rawKurztext[0].children[0].text : page.title} image={page.cover ? page.cover.asset.gatsbyImageData : ""}/>
        <BookLanding title={page.title} authors={page.Authors} subtitle={page.subtitle} cover={page.cover ? page.cover.asset.gatsbyImageData : null} images={page.seitenbilder}></BookLanding>
        {page._rawKurztext || page._rawLangtext ? <BookTexte shorttext={page._rawKurztext} longtext={page._rawLangtext} link={location.href}></BookTexte> : <></>}
        {page.images.length > 0 ? <BookImages images={page.images} title={page.title}></BookImages> : <></>}
        {page.ausgaben.length > 0  ? page.ausgaben.map(el => {
            return <Ausgabe kauflink={el.kauflink} isbn={el.isbn} text={el._rawInfo}></Ausgabe>
        }) : <></>}
        {page.Authors.length > 0  ? <BookAuthor authors={page.Authors}></BookAuthor> : <></>}
        {page.pressestimmen.length > 0  ? <PresseStimmen presse={page.pressestimmen}></PresseStimmen> : <></>}
        {page.events.length > 0 ? <Termine termine={page.events}></Termine> : <></>}
        {page.downloads.length > 0  ? <Downloads downloads={page.downloads}></Downloads> : <></>}
    </Layout>
  )
}

export default BookPage



/*
cover={page.cover.asset.gatsbyImageData} images={page.seitenbilder}
 */
