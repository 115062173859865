import React, {useEffect, useRef} from "react";
import MyPortableText from "./MyPortableText";
import Headline from "./Headline";
import Icon from "./Icon";
import {headlineAnimation} from "../Animation/animation";


const BookAuthor = ({authors}) => {

    return (
        <div className={"authors__outer container this"}>
            <div className="authors__inner">
                <Headline>Autor:Innen</Headline>
                <div className="text__container">
                    {authors.map(el => {
                        return <div className="text minion">
                            <MyPortableText value={el._rawText}></MyPortableText>
                            {el.website ?
                                <a href={el.website} target={"_blank"} className={"hover-moveup"}><Icon icon={"website"}></Icon></a> : <></>}
                            {el.facebook ?
                                <a href={el.facebook} target={"_blank"} className={"hover-moveup"}><Icon icon={"facebook"}></Icon></a> : <></>}
                            {el.instagram ?
                                <a href={el.instagram} target={"_blank"} className={"hover-moveup"}><Icon icon={"instagram"}></Icon></a> : <></>}
                            {el.twitter ?
                                <a href={el.twitter} target={"_blank"} className={"hover-moveup"}><Icon icon={"twitter"}></Icon></a> : <></>}
                            {el.youtube ?
                                <a href={el.youtube} target={"_blank"} className={"hover-moveup"}><Icon icon={"youtube"}></Icon></a> : <></>}
                            {el.tiktok ?
                                <a href={el.tiktok} target={"_blank"} className={"hover-moveup"}><Icon icon={"tiktok"}></Icon></a> : <></>}
                            {el.goodreads ?
                                <a href={el.goodreads} target={"_blank"} className={"hover-moveup"}><Icon icon={"goodreads"}></Icon></a> : <></>}
                            {el.linkedin ?
                                <a href={el.linkedin} target={"_blank"} className={"hover-moveup"}><Icon icon={"linkedin"}></Icon></a> : <></>}
                        </div>;
                    })}
                </div>

            </div>
        </div>
    )
}


export default BookAuthor
